body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.anychart-credits-text {
  display: none !important;
}

.anychart-credits-logo {
  display: none !important;
}

body {
  background-color: #152238 !important;
}

h1, h2, h3, h4, h5, h6, p {
  color: white;
}

path[data-ac-wrapper-id="779"] {
  fill: #192841;
}

div.timeline text {
  fill: white
}